import Dropdown from "react-bootstrap/Dropdown";
import { setSelectedStrategy } from "features/sandBox/sandBoxSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";

function Strategies(props) {
  const availableStrategies = ["Early", "Late", "Ultra-late"];
  const selectedStrategy = useSelector(
    (state) => state.sandBox.selectedStrategy
  );
  const dispatch = useDispatch();

  const strategiesList = availableStrategies.map((strategyName) => (
    <Dropdown.Item
      key={strategyName}
      onClick={() => {
        dispatch(setSelectedStrategy(strategyName));
      }}
    >
      {strategyName}
    </Dropdown.Item>
  ));

  return (
    <DropdownButton
      as={ButtonGroup}
      title={
        selectedStrategy
          ? "Materialization: " + selectedStrategy
          : "Materialization"
      }
    >
      {strategiesList}
    </DropdownButton>
  );
}

export default Strategies;
