import Button from "react-bootstrap/Button";
import sendQuery from "../../sendQuery";
import {
  setPendingState,
  setQueryResult,
} from "../../../../../features/sandBox/sandBoxSlice";
import React from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { useDispatch, useSelector } from "react-redux";

function QueryButton(props) {
  const dispatch = useDispatch();
  const selectedSchema = useSelector((state) => state.sandBox.selectedSchema);
  const queryText = useSelector((state) => state.sandBox.queryText);
  const selectedStrategy = useSelector(
    (state) => state.sandBox.selectedStrategy
  );
  return (
    <ButtonGroup>
      <Button
        onClick={() =>
          sendQuery(
            queryText,
            selectedSchema,
            selectedStrategy,
            (val) => dispatch(setQueryResult(val)),
            (val) => dispatch(setPendingState(val)),
            "query"
          )
        }
        variant="success"
      >
        Query
      </Button>
    </ButtonGroup>
  );
}

export default QueryButton;
