import React, {useContext} from "react";
import { useSelector } from "react-redux";
import Segment from "components/Segment/Segment";
import Editor from "./Editor/Editor";
import ContentContext from "ContentContext";
import PageText from "components/PageText/PageText";
import ResultTable from "./Editor/ResultTable/ResultTable";
import Plan from "./Editor/Plan/Plan";
import ErrorMessage from "./Editor/ErrorMessage/ErrorMessage";

function Sandbox() {
  const content = useContext(ContentContext);
  const resultType = useSelector((state) => state.sandBox.resultType);
  const queryResult = useSelector((state) => state.sandBox.queryResult);
  const resultIsEmpty =
    queryResult !== null && Object.keys(queryResult).length === 0;


  return (
    <>
      <Segment title="Interactive demo">
        <PageText text={content.SandboxText}></PageText>
        <Editor></Editor>
      </Segment>

      {resultType === "query" && !resultIsEmpty && (
        <Segment id="Result" title="Query Result">
          <ResultTable></ResultTable>
        </Segment>
      )}

      {resultType === "plan" && !resultIsEmpty && (
        <Segment id="Result" title="Query Plan">
          <Plan></Plan>
        </Segment>
      )}

      {resultType === "error" && <ErrorMessage></ErrorMessage>}
    </>
  );
}

export default Sandbox;
