import Dropdown from "react-bootstrap/Dropdown";
import { setSelectedSchema } from "features/sandBox/sandBoxSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";

function Schemas(props) {
  const availableSchemas = ["SSB"];
  const dispatch = useDispatch();
  const selectedSchema = useSelector((state) => state.sandBox.selectedSchema);
  const schemasList = availableSchemas.map((schemaName) => (
    <Dropdown.Item
      key={schemaName}
      onClick={() => {
        dispatch(setSelectedSchema(schemaName));
      }}
    >
      {schemaName}
    </Dropdown.Item>
  ));

  return (
    <DropdownButton
      as={ButtonGroup}
      title={selectedSchema ? "Schema: " + selectedSchema : "Schemas"}
    >
      {schemasList}
    </DropdownButton>
  );
}

export default Schemas;
