import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import React, {useContext} from "react";
import "./Gallery.sass";
import ContentContext from "../../ContentContext";
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw'
import Image from 'react-bootstrap/Image'

function Gallery() {
  const content = useContext(ContentContext);

  function Slide(imageUrl, text, id) {
    return (
      <Carousel.Item key={id}>
        <Container>
          <Row xs={1} sm={1} md={1} lg={1}>
            <Col xl="auto" className="slideImageCol">
              <Image src={imageUrl} alt="First slide label" />
            </Col>
            <Col xs md xl={true}>
              <ReactMarkdown rehypePlugins={[rehypeRaw]} children={text}></ReactMarkdown>
            </Col>
          </Row>
        </Container>
      </Carousel.Item>
    );
  }

  const slides = content.Gallery.map((slide) => {
    return Slide(slide.ImageURL, slide.Text, slide.id);
  });

  return (
    <Container fluid={true} id="Gallery" var="true">
      <Col>
        <Carousel>{slides}</Carousel>
      </Col>
    </Container>
  );
}

export default Gallery;
