import { useEffect } from "react";
import cytoscape from "cytoscape/dist/cytoscape.esm";
import tippy, { followCursor } from "tippy.js";
import dagre from "cytoscape-dagre";

cytoscape.use(dagre);

function Cytoscape(props) {
  let cyId = props.cyId;
  // a workaround to solve the bug with tippy (dirty hack actually)
  let tippyInstance = tippy(document.getElementById("root"), {
    followCursor: "initial",
    plugins: [followCursor],
    arrow: false,
    trigger: "manual",
  });

  const paddingByAmountBreakpoint = 5; // includes both nodes and edges; 5 = 3 nodes + 2 edges

  const layout = {
    name: "dagre",
    directed: true,
    padding: props.elements.length > paddingByAmountBreakpoint ? 50 : 150,
    rankSep: 60,
    nodeDimensionsIncludeLabels: true,
  };

  const stylesheet = [
    {
      selector: "node",
      css: {
        "background-color": "#F8CECC",
        width: (node) => {
          return node.data("nodeName").length * 14;
        },
        height: "35px",
        shape: "rectangle",
        "border-width": 2,
        "border-color": "#B85450",
      },
    },
    {
      selector: "node[nodeName]",
      style: {
        label: "data(nodeName)",
        color: "black",
        "text-halign": "center",
        "text-valign": "center",
        "font-family": "roboto",
        "font-size": "17px",
      },
    },
    {
      selector: "node[reader]",
      style: {
        "background-color": "#fff3c9",
        "border-color": "#cbc392",
        "border-width": "2px",
      },
    },
    {
      selector: "edge",
      style: {
        width: 2,
        "line-color": "#000000",
        "target-arrow-color": "#000000",
        "target-arrow-shape": "triangle-backcurve",
        "arrow-scale": 1.7,
        "curve-style": "bezier",
      },
    },
    {
      selector: "$node > node",
      style: {
        "background-color": "#d9e8fd",
        "border-width": "2px",
        "border-color": "#8a9eb9",
        shape: "rectangle",
      },
    },
  ];

  function getModalHTML(nodeData) {
    let modalContent = '<div class="nodeInfo">';

    if ("base_type" in nodeData) {
      modalContent += `<b>Base type: </b> ${nodeData.base_type}</br>`;
    }

    if ("type" in nodeData) {
      modalContent += `<b>Type: </b> ${nodeData.type}</br>`;
    }

    if ("label" in nodeData) {
      modalContent += `<b>Label: </b> ${nodeData.label}</br>`;
    }

    if ("value" in nodeData) {
      modalContent += `<b>Value: </b> ${nodeData.value}</br>`;
    }

    if ("readValue" in nodeData) {
      modalContent += `<b>Value: </b></br> ${nodeData.readValue}`;
    }

    modalContent += "</div>";
    return modalContent;
  }

  useEffect(() => {
    let cy = cytoscape({
      container: document.getElementById(cyId),
      style: stylesheet,
      elements: props.elements,
      layout: layout,
      wheelSensitivity: 0.5,
    });

    function addEdge(source, target) {
      cy.add({
        group: "edges",
        data: {
          source: source,
          target: target,
          notReader: "true",
        },
      });
    }

    for (const groupNode of cy.nodes("$node > node")) {
      const operator = groupNode.children("[notReader]")[0],
        readers = groupNode.children("[reader]"),
        opId = operator.id();

      function moveReader(reader, direction) {
        const rWidth = reader.width(),
          margin = 40,
          x = operator.position("x"),
          y = operator.position("y"),
          opWidth = operator.width();

        if (direction === "right") {
          reader.position({ x: x + opWidth / 2 + rWidth / 2 + margin, y: y });
        } else {
          reader.position({ x: x - opWidth / 2 - rWidth / 2 - margin, y: y });
        }
      }

      if (readers.length === 1) {
        const reader = groupNode.children("[reader]")[0];
        moveReader(reader, "right");
        addEdge(opId, reader.id());
      }

      if (readers.length === 2) {
        const reader0 = groupNode.children("[reader]")[0],
          reader1 = groupNode.children("[reader]")[1];
        moveReader(reader1, "right");
        moveReader(reader0, "left");
        addEdge(opId, reader1.id());
        addEdge(opId, reader0.id());
      }
    }

    cy.on("tap", "node", function (evt) {
      let node = evt.target;
      if (node.data().nodeName === "") return;
      tippyInstance = tippy(document.getElementById(cyId), {
        followCursor: "initial",
        plugins: [followCursor],
        trigger: "manual",
        inlinePositioning: true,
        content: getModalHTML(node.data()),
        allowHTML: true,
        theme: "transparent",
        arrow: false,
      });
      tippyInstance.show();
    });

    cy.on("mouseover", "node", function (evt) {
      document.body.style.cursor = "pointer";
    });
    cy.on("mouseout", "node", function (evt) {
      document.body.style.cursor = "auto";
    });

    document.getElementById(cyId).addEventListener("wheel", (event) => {
      if (tippyInstance) {
        tippyInstance.hide();
      }
    });
  }, [props.elements]);

  return <div className="cy" id={cyId}></div>;
}

export default Cytoscape;
