import "./Plan.sass";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PlanHelp from "./PlanHelp/PlanHelp";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/translucent.css";
import Plot from "./Plot/Plot";
import { useEffect } from "react";

function Plan() {
  useEffect(() => {
    document.getElementById("Result").scrollIntoView();
  });

  return (
    <div id="plan">
      <Tabs
        defaultActiveKey="logical"
        id="sandboxTabs"
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <Tab eventKey="logical" title="Logical plan">
          <Plot type="LOGICAL TREE"></Plot>
          <PlanHelp></PlanHelp>
        </Tab>
        <Tab eventKey="physical" title="Physical plan">
          <Plot type="PHYSICAL TREE"></Plot>
          <PlanHelp></PlanHelp>
        </Tab>
        <Tab eventKey="engine" title="Engine plan">
          <Plot type="ENGINE TREE"></Plot>
          <PlanHelp></PlanHelp>
        </Tab>
      </Tabs>
    </div>
  );
}

export default Plan;
