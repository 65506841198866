import React from "react";
import Row from "react-bootstrap/Row";
import "./PageHeader.sass";

const PageHeader = (props) => {
  return (
    <Row className="page-header">
      <span>{props.title}</span>
      <div className="page-header-border"></div>
    </Row>
  );
};

export default PageHeader;
