import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw'
import "./PageText.sass"

function PageText(props) {
  return (
    <>
      {props.text && (
        <ReactMarkdown className="text" rehypePlugins={[rehypeRaw]} children={props.text}></ReactMarkdown>
      )}
    </>
  );
}

export default PageText;
