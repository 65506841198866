import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import "./Home.sass";
import logo from "./res/logo-white.svg";
import { HashLink } from "react-router-hash-link";

const Home = () => {
  return (
    <Container fluid>
      <Row id="Home" className="align-items-center justify-content-md-center">
        <div className="col-md-5 text-center">
          <img className="home-logo" src={logo} alt=""></img>
          <h1 className="mb-3">Materialization done right!</h1>
          <div className="d-flex mb-3 align-items-center justify-content-center">
            <p className="description">
              A fast analytic column-store with SQL support. Achieving
              performance with vision, technology and expertise.
            </p>
          </div>
          <HashLink to="/Sandbox">
            <Button variant="danger">
              Sandbox
            </Button>{" "}
          </HashLink>
        </div>
      </Row>
    </Container>
  );
};

export default Home;
