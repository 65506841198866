import Alert from "react-bootstrap/Alert";
import React from "react";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";

function ErrorMessage() {
  const errMessage = useSelector((state) => state.sandBox.errMessage);
  return (
    <Container className="mt-5">
      <Alert variant="danger">
        {errMessage}
      </Alert>
    </Container>
  );
}

export default ErrorMessage;
