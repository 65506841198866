import { useState, useEffect } from "react";

function useSiteContent() {
  const [content, setContent] = useState({
    Team: [],
    ExTeam: [],
    Publications: [],
    Examples: [],
    Gallery: []
  });

  useEffect(() => {
    let url = process.env.REACT_APP_STRAPI_HOST + "/posdb";
    fetch(url, { method: "GET" })
      .then((res) => res.json())
      .then(
        (result) => setContent(result),
        (error) => {}
      );
  }, []);
  return content;
}

export default useSiteContent;
