import { createSlice } from "@reduxjs/toolkit";

export const sandBoxSlice = createSlice({
  name: "sandBox",
  initialState: {
    pending: false,
    queryText: "",
    queryResult: null, // null or plan or query result
    resultType: null, // null | "query" | "plan" | "error"
    errMessage: null,
    selectedExampleName: null,
    selectedSchema: "SSB",
    selectedStrategy: "Early",
  },
  reducers: {
    setPendingState: (state, newState) => {
      state.pending = newState.payload;
    },
    setQueryText: (state, action) => {
      if (state.selectedExampleName && action.payload !== state.queryText) {
        state.selectedExampleName = null;
      }
      state.queryText = action.payload;
    },
    clearCodeArea: (state) => {
      state.queryText = "";
      state.selectedExampleName = null;
    },
    setQueryResult: (state, action) => {
      if (action.payload.type === "error") {
        state.resultType = "error";
        state.queryResult = null;
        state.errMessage = action.payload.errMessage;
        return;
      }
      state.queryResult = action.payload.result;
      state.resultType = action.payload.type;
    },
    setSelectedExampleName: (state, action) => {
      state.selectedExampleName = action.payload;
    },
    setSelectedSchema: (state, action) => {
      state.selectedSchema = action.payload;
    },
    setSelectedStrategy: (state, action) => {
      state.selectedStrategy = action.payload;
    },
  },
});

export const {
  setPendingState,
  setQueryText,
  clearCodeArea,
  setQueryResult,
  setSelectedExampleName,
  setSelectedSchema,
  setSelectedStrategy,
} = sandBoxSlice.actions;

export default sandBoxSlice.reducer;
