import {Pagination} from "react-bootstrap";
import {useSelector} from "react-redux";

function CustomPagination(props) {
  const queryResult = useSelector((state) => state.sandBox.queryResult);
  const firstTablePage = () => props.setTablePage(1);
  const nextTablePage = () =>
    props.setTablePage(Math.min(props.tablePage + 1, getMaxPage()));
  const prevTablePage = () => props.setTablePage(Math.max(props.tablePage - 1, 1));
  const lastTablePage = () => props.setTablePage(getMaxPage());
  const getMaxPage = () => Math.ceil(queryResult.body.length / 10);

  return (
    <Pagination className="justify-content-end">
      <Pagination.First onClick={firstTablePage} />
      <Pagination.Prev onClick={prevTablePage} />

      {props.tablePage - 2 > 0 && props.tablePage === getMaxPage() && (
        <Pagination.Item onClick={() => props.setTablePage(props.tablePage - 2)}>
          {props.tablePage - 2}
        </Pagination.Item>
      )}

      {props.tablePage - 1 > 0 && (
        <Pagination.Item onClick={prevTablePage}>
          {props.tablePage - 1}
        </Pagination.Item>
      )}
      <Pagination.Item active>{props.tablePage}</Pagination.Item>
      {props.tablePage + 1 <= getMaxPage() && (
        <Pagination.Item onClick={nextTablePage}>
          {props.tablePage + 1}
        </Pagination.Item>
      )}

      {props.tablePage + 2 <= getMaxPage() && props.tablePage === 1 && (
        <Pagination.Item onClick={() => props.setTablePage(props.tablePage + 2)}>
          {props.tablePage + 2}
        </Pagination.Item>
      )}

      <Pagination.Next onClick={nextTablePage} />
      <Pagination.Last onClick={lastTablePage} />
    </Pagination>
  );
}

export default CustomPagination;
