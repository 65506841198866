export function getTreeElements(node, parentId = -1) {
  let id = 0;

  function dfs(node, parentId = -1) {
    const nodeId = "n" + id;
    id++;

    let elements = [];

    let data = {
      nodeName: node.type,
      type: node.type,
      id: nodeId,
      notReader: "true",
    };

    if ("label" in node) {
      data.label = node.label;
    }
    if ("value" in node) {
      if (typeof node.value === "string") data.value = node.value;
      else data.value = JSON.stringify(node.value);
    }

    if ("base_type" in node) {
      data.base_type = node.base_type;
    }

    function pushNode() {
      elements.push({
        group: "nodes",
        data: data,
      });
    }

    if ("readers" in node) {
      elements.push({
        group: "nodes",
        data: {
          id: "group_" + nodeId,
          name: "group_" + nodeId,
          nodeName: "",
          notReader: "true",
        },
      });
      data.parent = "group_" + nodeId;

      let readValue = "";
      for (const reader of node["readers"]) {
        readValue += `${reader} </br>`;
      }

      function pushReader(nodeName) {
        elements.push({
          group: "nodes",
          data: {
            nodeName: nodeName,
            parent: "group_" + nodeId,
            reader: "true",
            readValue: readValue,
          },
        });
      }

      if (node["readers"].length === 1) {
        pushNode();
        pushReader(`Read(${node["readers"][0]})`);
      } else if (node["readers"].length === 2) {
        pushReader(`Read(${node["readers"][0]})`);
        pushNode();
        pushReader(`Read(${node["readers"][1]})`);
      } else {
        pushNode();
        pushReader(`Read(...)`);
      }
    } else {
      pushNode();
    }

    if (parentId !== -1) {
      elements.push({
        group: "edges",
        data: {
          source: parentId,
          target: nodeId,
          notReader: "true",
        },
      });
    }

    if ("children" in node) {
      for (let i = 0; i < node.children.length; i++) {
        let childNode = node.children[i];
        elements = elements.concat(dfs(childNode, nodeId));
      }
    }
    return elements;
  }

  return dfs(node, parentId);
}
