import React, { useContext } from "react";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Publication from "./components/Publication";
import PageText from "components/PageText/PageText";
import "./Publications.sass";
import ContentContext from "ContentContext";

const Publications = () => {
  const content = useContext(ContentContext);

  const publications = content.Publications.map((item) => (
    <Publication
      key={item.id}
      name={item.Name}
      at={item.Venue}
      arxiv={item.Arxiv}
      link={item.Link}
      bib={item.Bibtex}
    ></Publication>
  ));

  return (
    <>
      <Col>
        <PageText text={content.PublicationsText}></PageText>
        <Table id="publicationsTable" striped bordered responsive>
          <thead>
            <tr>
              <th>Publication</th>
              <th>Venue</th>
              <th>Arxiv</th>
              <th>Link</th>
              <th>.bib</th>
            </tr>
          </thead>
          <tbody>{publications}</tbody>
        </Table>
      </Col>
    </>
  );
};

export default Publications;
