import React, { useContext } from "react";
import Row from "react-bootstrap/Row";
import DeveloperCard from "components/DeveloperCard/DeveloperCard";
import ContentContext from "ContentContext";
import "./Team.sass";

const Team = () => {
  const content = useContext(ContentContext);

  function getDeveloperCard(developer, muted=false, id) {
    return (
      <DeveloperCard key={id}
        name={developer.Name}
        info={developer.Role}
        contacts={developer.Email}
        muted={muted}
      ></DeveloperCard>
    );
  }

  const developersCards = content.Team.map((developer) => {
    return getDeveloperCard(developer, false, developer.id);
  });

  const exDevelopersCards = content.ExTeam.map((developer) => {
    return getDeveloperCard(developer, true, developer.id);
  });

  return (
    <>
      <Row xs={1} sm={1} md={2} lg={3}>
        {developersCards}
      </Row>
      <h4 id="ExTeamMembers">Ex-team members</h4>
      <Row xs={1} sm={1} md={2} lg={3}>
        {exDevelopersCards}
      </Row>
    </>
  );
};

export default Team;
