import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Publication.sass";

const Publication = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFocus = (event) => {
    let range = document.createRange();
    range.selectNodeContents(event.target);
    let sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  };

  return (
    <>
      <tr className="publication">
        <td>{props.name}</td>
        <td>{props.at}</td>
        <td>
          {props.arxiv && (
            <a target="_blank" rel="noopener noreferrer" href={props.arxiv}>
              link
            </a>
          )}
        </td>
        <td>
          {props.link && (
            <a target="_blank" rel="noopener noreferrer" href={props.link}>
              link
            </a>
          )}
        </td>
        <td>
          {props.bib && (
            <span className="bibTex" onClick={handleShow}>
              bib
            </span>
          )}
        </td>
      </tr>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>BibTeX</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre onClick={handleFocus} className="form-control bibtex-text">
            {props.bib}
          </pre>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Publication;
