function returnEmptyQueryError(setQueryResult) {
  setQueryResult({ type: "error", errMessage: "Query cannot be empty" });
}

function sendQuery(query, schema, strategy, setQueryResult, setPending, type) {
  if (query === "") {
    returnEmptyQueryError(setQueryResult);
    return;
  }
  setPending(true);
  setQueryResult({ type: null, result: null });
  let url = process.env.REACT_APP_SERVER_HOST + "/api/run_query";
  if (type === "plan") {
    url = process.env.REACT_APP_SERVER_HOST + "/api/get_plan";
  }
  fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: query,
      db_schema: schema,
      strategy: strategy,
    }),
  })
    .then((res) => {
      if (!res.ok) {
        return res.json().then((result) => {
          if (result["detail"]) {
            throw new Error(result["detail"]);
          } else {
            throw new Error(res.statusText);
          }
        });
      } else {
        return res.json();
      }
    })
    .then((result) => {
      setQueryResult({ type: type, result: result });
    })
    .catch((error) => {
      console.log(error);
      setQueryResult({ type: "error", errMessage: error.message });
    })
    .finally(() => {
      setPending(false);
    });
}

export default sendQuery;
