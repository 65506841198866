import React, { useContext } from "react";
import Col from "react-bootstrap/Col";
import PageText from "components/PageText/PageText";
import ContentContext from "ContentContext";

const Summary = () => {
  const content = useContext(ContentContext);

  return (
    <Col>
      <PageText text={content.SummaryText}></PageText>
    </Col>
  );
};

export default Summary;
