import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import "./Footer.sass";

const Footer = () => {
  return (
    <Container fluid id="footer">
      <Row className="justify-content-center">
        <p>Copyright 2016-2024 PosDB Team</p>
        <img
          alt=""
          src="/logo-white.svg"
          width="91"
          height="51"
          className="d-inline-block align-top"
        />
      </Row>
    </Container>
  );
};

export default Footer;
