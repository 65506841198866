import './PlanHelp.sass'
import { Cursor, ZoomIn, HandIndexThumb } from "react-bootstrap-icons";
import React from "react";

function PlanHelp() {
    return (
        <>
            <div className="planHelp">
                <p><Cursor className="tipIcon" /> Drag the canvas to move the graph</p>
                <p><HandIndexThumb className="tipIcon" /> Click on a node to show more info</p>
                <p><ZoomIn className="tipIcon" /> Use scroll wheel to zoom</p>
            </div>
        </>
    );
}

export default PlanHelp;
