import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Navbar.sass";
import { HashLink } from "react-router-hash-link";
import { useState } from "react";

const TopBar = () => {
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  const toggleNavbar = () => {
    setNavbarExpanded(!navbarExpanded);
  };
  const closeNavbar = () => {
    setNavbarExpanded(false);
  };

  return (
    <Navbar
      fixed="top"
      id="navbar"
      bg="white"
      variant="light"
      expand="md"
      collapseOnSelect
      expanded={navbarExpanded}
    >
      <Container>
        <HashLink onClick={closeNavbar} to="/#Home">
          <Navbar.Brand>
            <img
              alt=""
              src="/logo.svg"
              width="75"
              height="42"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
        </HashLink>
        <Navbar.Toggle
          onClick={toggleNavbar}
          aria-controls="basic-navbar-nav"
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <HashLink onClick={closeNavbar} className="nav-link" to="/#Home">
              Home
            </HashLink>
            <HashLink onClick={closeNavbar} className="nav-link" to="/#Summary">
              Summary
            </HashLink>
            <HashLink onClick={closeNavbar} className="nav-link" to="/#Team">
              Team
            </HashLink>
            <HashLink
              onClick={closeNavbar}
              className="nav-link"
              to="/#Publications"
            >
              Publications
            </HashLink>
            <HashLink onClick={closeNavbar} className="nav-link" to="/Sandbox">
              Sandbox
            </HashLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default TopBar;
