import "./App.sass";
import TopBar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { Routes, Route, useLocation } from "react-router-dom";
import Sandbox from "../Sandbox/Sandbox";
import { ContentProvider } from "ContentContext";
import useSiteContent from "functions";
import { useEffect, useState } from "react";
import MainPage from "../MainPage/MainPage";

function App() {
  const location = useLocation();

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  useEffect(() => {
    function userJumpedBetweenSections() {
      // Check whether a user used an anchor while being on the main page
      const mainPagesGroup = ["#Home", "#Summary", "#Team", "#Publications"];
      return (
        mainPagesGroup.includes(location.hash) &&
        mainPagesGroup.includes(displayLocation.hash)
      );
    }

    if (location !== displayLocation && !userJumpedBetweenSections()) {
      setTransistionStage("fadeOut");
    }
  }, [location, displayLocation]);

  return (
    <>
      <TopBar />
      <ContentProvider value={useSiteContent()}>
        <div
          className={`${transitionStage} content`}
          onAnimationEnd={() => {
            if (transitionStage === "fadeOut") {
              setTransistionStage("fadeIn");
              setDisplayLocation(location);
            }
          }}
        >
          <Routes location={displayLocation}>
            <Route exact path="/" element={<MainPage />} />
            <Route path="/Sandbox" element={<Sandbox />} />
          </Routes>
        </div>
      </ContentProvider>
      <Footer />
    </>
  );
}

export default App;
