import React from "react";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { FileEarmark } from "react-bootstrap-icons";
import "./EditorPanel.sass";
import { useDispatch, useSelector } from "react-redux";
import { clearCodeArea } from "features/sandBox/sandBoxSlice";
import Schemas from "./Options/Schemas";
import Examples from "./Options/Examples";
import Strategies from "./Options/Strategies";
import QueryButton from "./QueryButtons/QueryButton";
import PlansButton from "./QueryButtons/PlansButton";
import InfoButton from "./InfoButton/InfoButton";

function EditorPanel() {
  const dispatch = useDispatch();
  const pending = useSelector((state) => state.sandBox.pending);

  return (
    <div id="EditorPanel">
      <Container>
        <Row>
          <ButtonToolbar
            className="justify-content-between"
            aria-label="Toolbar with button groups"
          >
            <div className="sandboxOptions">
              <ButtonGroup className="me-2">
                <Button
                  onClick={() => {
                    dispatch(clearCodeArea());
                  }}
                  className="d-flex align-items-center"
                >
                  <FileEarmark />
                </Button>
                <Examples></Examples>
              </ButtonGroup>
              <ButtonGroup className="me-2">
                <Strategies></Strategies>
              </ButtonGroup>
              <ButtonGroup className="me-2">
                <InfoButton></InfoButton>
                <Schemas></Schemas>
              </ButtonGroup>
            </div>

            <div className="spinnerAndQueryButtons">
              <Spinner
                className={!pending && "d-none"}
                id="spinner"
                animation="border"
                variant="success"
              />
              <div
                className={
                  pending ? "queryButtonsFadeOut" : "queryButtonsFadeIn"
                }
              >
                <PlansButton></PlansButton>
                <QueryButton></QueryButton>
              </div>
            </div>
          </ButtonToolbar>
        </Row>
      </Container>
    </div>
  );
}

export default EditorPanel;
