import Dropdown from "react-bootstrap/Dropdown";
import {
  setQueryText,
  setSelectedExampleName,
  setSelectedSchema,
} from "features/sandBox/sandBoxSlice";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentContext from "../../../../../ContentContext";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import DropdownButton from "react-bootstrap/DropdownButton";

function Examples(props) {
  const dispatch = useDispatch();
  const content = useContext(ContentContext);
  const selectedExampleName = useSelector(
    (state) => state.sandBox.selectedExampleName
  );

  const queryExamples = content.Examples.map((Example) => (
    <Dropdown.Item
      key={Example.id}
      onClick={() => {
        dispatch(setQueryText(Example.Code));
        dispatch(setSelectedExampleName(Example.Name));
        dispatch(setSelectedSchema(Example.Schema));
      }}
    >
      {Example.Name}
    </Dropdown.Item>
  ));
  return (
    <DropdownButton
      as={ButtonGroup}
      title={
        selectedExampleName
          ? "Example: " + selectedExampleName
          : "Queries examples"
      }
    >
      {queryExamples}
    </DropdownButton>
  );
}

export default Examples;
