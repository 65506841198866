import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getTreeElements } from "../graph";
import Cytoscape from "../Cytoscape";

function Plot(props) {
  const queryResult = useSelector((state) => state.sandBox.queryResult);
  const tree = queryResult[props.type];
  const [elements, setElements] = useState([]);

  useEffect(() => {
    const new_elements = getTreeElements(tree);
    setElements(new_elements);
  }, [queryResult, tree]);

  switch (props.type) {
    case "PHYSICAL TREE":
      return <Cytoscape elements={elements} cyId="PhysicalPlanCy"></Cytoscape>;
    case "LOGICAL TREE":
      return <Cytoscape elements={elements} cyId="LogicalPlanCy"></Cytoscape>;
    case "ENGINE TREE":
      return <Cytoscape elements={elements} cyId="EnginePlanCy"></Cytoscape>;
    default:
      return <></>;
  }
}

export default Plot;
