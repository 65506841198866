import "./DeveloperCard.sass";
import Col from "react-bootstrap/Col";

const DeveloperCard = (props) => {
  return (
    <>
      <Col className="developerCard">
          <p className="developerName">{props.name}</p>
        <p className="developerInfo">{props.info}</p>
        {props.contacts && (
          <p className="developerContacts">{props.contacts}</p>
        )}
      </Col>
    </>
  );
};

export default DeveloperCard;
