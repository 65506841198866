import "./Segment.sass";
import Container from "react-bootstrap/Container";

import PageHeader from "../PageHeader/PageHeader";

function Segment(props) {
  return (
    <Container id={props.id} className="segment">
      <PageHeader title={props.title} />
      {props.children}
    </Container>
  );
}

export default Segment;
