import Home from "../Home/Home";
import Segment from "../Segment/Segment";
import Summary from "../Summary/Summary";
import Team from "../Team/Team";
import Gallery from "../Gallery/Gallery"
import Publications from "../Publications/Publications";

function MainPage() {
  return (
    <>
      <Home />
      <Segment title="Summary" id="Summary">
        <Summary />
      </Segment>
      <Gallery />
      <Segment title="Team" id="Team">
        <Team />
      </Segment>
      <Segment title="Publications" id="Publications">
        <Publications />
      </Segment>
    </>
  );
}

export default MainPage;
