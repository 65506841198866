import CodeMirror from "@uiw/react-codemirror";
import { sql } from "@codemirror/lang-sql";
import EditorPanel from "./EditorPanel/EditorPanel";
import "./Editor.sass";
import { useDispatch, useSelector } from "react-redux";
import { setQueryText } from "features/sandBox/sandBoxSlice";

function Editor() {
  const dispatch = useDispatch();
  const queryText = useSelector((state) => state.sandBox.queryText);

  return (
    <div id="editor">
      <EditorPanel></EditorPanel>
      <CodeMirror
        id="codeArea"
        value={queryText}
        extensions={[sql({})]}
        onChange={(value, viewUpdate) => {
          dispatch(setQueryText(value));
        }}
      />
    </div>
  );
}

export default Editor;
