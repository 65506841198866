import {useEffect, useState} from "react";
import "./ResultTable.sass";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import CustomPagination from "./CustomPagination/CustomPagination";

function ResultTable() {
  const queryResult = useSelector((state) => state.sandBox.queryResult);

  const [tablePage, setTablePage] = useState(1);

  const headerItems = queryResult.columns.map((item) => <th>{item}</th>);
  const getBodyItems = () =>
    queryResult.body.slice((tablePage - 1) * 10, tablePage * 10).map((row) => (
      <tr>
        {row.map((item) => {
          return <th>{item}</th>;
        })}
      </tr>
    ));

  useEffect(() => {
    document.getElementById("Result").scrollIntoView();
  })

  return (
    <>
      <div id="resultTable">
        <Table striped hover responsive>
          <thead>
            <tr>{headerItems}</tr>
          </thead>
          {queryResult.body !== null && <tbody>{getBodyItems()}</tbody>}
        </Table>
        {queryResult.body !== null && (
          <CustomPagination setTablePage={setTablePage} tablePage={tablePage}></CustomPagination>
        )}
      </div>
    </>
  );
}

export default ResultTable;
